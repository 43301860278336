import React, { Component } from "react"
import Web3 from "web3"
import Farm from "../abis/Farm.json"
import ERC20 from "../abis/ERC20.json"
import Navbar from "./Navbar"
import Main from "./Main"
import "./App.css"
import kiro from "../kirobo cube.png"




class App extends Component {
  async componentWillMount() {
    await this.loadWeb3()
    await this.loadBlockchainData()
  }

  async updateStakingBalance() {
    const web3 = window.web3
    const networkId = await web3.eth.net.getId()
    const tokenFarmData = Farm.networks[networkId]
    const tokenFarm = new web3.eth.Contract(
      Farm.abi,
      tokenFarmData.address
    )
  let stakingBalance = await tokenFarm.methods
      .getStakingBalance(this.state.account)
      .call()
    this.setState({ stakingBalance: stakingBalance.toString() })
    await this.updateRewardsBalance()
    await this.getFinalPayout()
  }

  async updateRewardsBalance() {
    const web3 = window.web3
    const networkId = await web3.eth.net.getId()
    const tokenFarmData = Farm.networks[networkId]
    const tokenFarm = new web3.eth.Contract(
      Farm.abi,
      tokenFarmData.address
    )
   
  let RewardsBalance = await tokenFarm.methods
      .getUserRewardAmount(this.state.account)
      .call()
    this.setState({ rewardsBalance: RewardsBalance.toString() }) 
    let totalRewardsPaid = await tokenFarm.methods
    .getTotalRewards(this.state.account)
    .call()
  this.setState({ totalRewardsPaid: totalRewardsPaid.toString() })
  let isLocked = await tokenFarm.methods
  .isLocked(this.state.account)
  .call()
this.setState({ isLocked: isLocked })

            
 await this.updateInfo()
  }

  updateInfo = async () => {
    const web3 = window.web3
    const networkId = await web3.eth.net.getId()
    const tokenFarmData = Farm.networks[networkId]
    const tokenFarm = new web3.eth.Contract(
      Farm.abi,
      tokenFarmData.address
    )
    let Historical = await tokenFarm.methods
    .getHistoricalStaked()
    .call()
  this.setState({ historicStaked: Historical })
  let Current = await tokenFarm.methods
    .getCurrentStaked()
    .call()
  this.setState({ currentStaked: Current })
  
let HRewards = await tokenFarm.methods
    .getTotalRewardsPaid()
    .call()
  this.setState({ historicRewards: HRewards })

 
await this.updateStatus()
 }



  

    


  updateStatus = async () => {
    let result = await this.state.tokenFarm.methods.programStatus().call({ from: this.state.account })
    this.setState({ Status: result })
    await this.updatePending()
       
    };
    updatePending = async () => {
      if (this.state.Status === true && this.state.account === "0xfb09EF6E6A538452bC1BFFbD62AA9391ceC49156"){
      let pending = await this.state.tokenFarm.methods.getTotalPendingReward().call({ from: this.state.account })
      this.setState({ pendingRewards: pending })}
      await this.getBalance()
         
      };

      getBalance = async () => {
        let balance = await this.state.tokenFarm.methods.getBalance().call({ from: this.state.account })
        this.setState({ contractBalance: balance })
        await this.getMinBalance()
           
        };

        getMinBalance = async () => {
          if (this.state.Status === true ) {
          let balance = await this.state.tokenFarm.methods.getMinBalance().call({ from: this.state.account })
          this.setState({ contractMinBalance: balance })
          }
          };

           async getUnlockTime  () {
             let timestamp = await this.state.tokenFarm.methods.getUnlockTime(this.state.account).call({ from: this.state.account })
            let date = (timestamp / 3600) / 24
          this.setState({unlockDate: Math.trunc(date.toString())})
          };
          
         // getFinalRewards = async () => {
           // if (this.state.Status == false && this.state.account !== "0x80C4e71961dE872Acd0dc04589190a86e12FB03e")
           // {
           // let reward = await this.state.tokenFarm.methods.calcFinalReward(this.state.account).call({ from: this.state.account })
            //this.setState({ finalRewards: reward })
            //}
          //  else if (this.state.Status == true){
            //  this.setState({ finalRewards: "0"})
            //}
            //};
         
         
     
  async loadBlockchainData() {
    const web3 = window.web3

    const accounts = await web3.eth.getAccounts()
    this.setState({ account: accounts[0] })
    this.setState({
      tokenAddress: "0xb1191f691a355b43542bea9b8847bc73e7abb137",
    })
    this.setState({ image: kiro })
    this.setState({ tokenName: "KIRO" })

    const networkId = await web3.eth.net.getId()

    const erc20 = new web3.eth.Contract(ERC20.abi, this.state.tokenAddress)
    this.setState({ erc20 })
    let erc20Balance = await erc20.methods.balanceOf(this.state.account).call()
    this.setState({ erc20Balance: erc20Balance.toString() })

  
    const tokenFarmData = Farm.networks[networkId]
    if (tokenFarmData) {
      const tokenFarm = new web3.eth.Contract(
        Farm.abi,
        tokenFarmData.address
      )
      this.setState({ tokenFarm })
     this.updateStakingBalance()
     this.getFinalRewards()
     this.getUnlockTime()
    } else {
      window.alert("TokenFarm contract not deployed to detected network.")
    }
    this.setState({ loading: false })
   
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    } else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    } else {
      window.alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      )
    }
  }



  stakeTokens = (amount) => {
   this.setState({ loading: true })
   this.state.erc20.methods
      .approve(this.state.tokenFarm._address, amount)
      .send({ from: this.state.account, type:'0x2' })
      .on("transactionHash", (hash) => {
        
        window.alert(
          "Approving spend limit. Please wait for Metamask popup to confirm transaction. Do not refresh the page or speed up approval."
        )
      })
      .on("receipt", (receipt) => {
       
        this.state.tokenFarm.methods
          .stakeTokens(amount)
          .send({ from: this.state.account, type:'0x2' })
          .on("transactionHash", (hash) => {
            this.setState({ loading: false })
            
            })
            .on("receipt", (receipt) => {
              window.location.reload(false);
          })
      
      })
  };

  unstakeTokens = () => {
    this.setState({ loading: true })
    this.state.tokenFarm.methods
      .unstake()
      .send({ from: this.state.account, type:'0x2'})
      .on("transactionHash", (hash) => {
        this.setState({ loading: false })
      })
      .on("receipt", (receipt) => {
        window.location.reload(false);
    })

  };

  withdrawRewards = () => {
    this.setState({ loading: true })
    this.state.tokenFarm.methods
      .withdrawRewardOnly()
      .send({ from: this.state.account, type:'0x2' })
      .on("transactionHash", (hash) => {
        this.setState({ loading: false })
      })
  };


  withdrawFinalBalance = () => {
    this.setState({ loading: true })
    this.state.tokenFarm.methods
      .withdrawFinalBalance()
      .send({ from: this.state.account, type:'0x2' })
      .on("transactionHash", (hash) => {
        this.setState({ loading: false })
      })
  };

 

  
  endProgram = async () => {
  await this.state.tokenFarm.methods.programOff().send({from: this.state.account, type:'0x2'})
   
     
  };

  programOn = async () => {
    await this.state.tokenFarm.methods.programOn().send({ from: this.state.account, type:'0x2' })
     
       
    };
  

  getStatus = async () => {
    let result = await this.state.tokenFarm.methods.programStatus()
    this.setState({ Status: result})
       
    };
  

  Blacklist = async (user) => {
    await this.state.tokenFarm.methods.BlackList(user).send({ from: this.state.account, type:'0x2' })
     
    };

    getFinalRewards = async () => {
           
      let reward = await this.state.tokenFarm.methods.calcFinalReward(this.state.account).call({ from: this.state.account })
      this.setState({ finalRewards: reward })
   
      };

     async getFinalPayout () {
           if (this.state.account === "0xfb09EF6E6A538452bC1BFFbD62AA9391ceC49156") {
        let finalpayout = await this.state.tokenFarm.methods.getFinalPayout().call({ from: this.state.account })
         this.setState({ finalPayout: Web3.utils.fromWei(finalpayout) })
      }
     if (this.state.Status == true){
        this.setState({ finalPayout: "0" })
      }
         
        };

  

  constructor(props) {
    super(props)
    this.state = {
      account: "0x0",
      erc20: {},
      Token: {},
      TokenAddress: "",
      tokenFarm: {},
      erc20Balance: "0",
      TokenBalance: "0",
      rewardsBalance: "0",
      stakingBalance: "0",
      loading: true,
      image: kiro,
      tokenName: "KIRO",
      totalRewardsPaid: "0",
      historicRewards: "0",
      isLocked: false,
      historicStaked: '0',
      currentStaked: '0',
      pendingRewards: '0',
      Status: true,
      contractBalance:"0",
      contractMinBalance:"0",
      finalRewards: "0",
      finalPayout: "0",
      unlockDate: "0",
    }
  }

  render() {
    let content
    if (this.state.loading) {
      content = (
        <div className="spinner-border large" role="status">
        <span className="sr-only">Loading...</span>
      </div>   
                
      )
    } else {
      content = (
        <Main
          account={this.state.account}
          erc20Balance={this.state.erc20Balance}
          kiroTokenBalance={this.state.TokenBalance}
          kiroTokenAddress={this.state.TokenAddress}
          stakingBalance={this.state.stakingBalance}
          stakeTokens={this.stakeTokens.bind(this)}
          unstakeTokens={this.unstakeTokens.bind(this)}
          withdrawRewards={this.withdrawRewards.bind(this)}
          tokenName={this.state.tokenName}
          image={this.state.image}
          tokenAddress={this.state.tokenAddress}
          rewardsBalance={this.state.rewardsBalance}
          totalRewardsPaid={this.state.totalRewardsPaid}
          historicStaked={this.state.historicStaked}
          currentStaked={this.state.currentStaked}
          pendingRewards={this.state.pendingRewards}
          endProgram={this.endProgram.bind(this)}
          Blacklist={this.Blacklist.bind(this)}
          programStatus={this.state.Status}
          isLocked={this.state.isLocked}
          getStatus={this.getStatus.bind(this)}
          withdrawFinalBalance={this.withdrawFinalBalance.bind(this)}
          historicRewards={this.state.historicRewards}
          contractBalance={this.state.contractBalance}
          contractMinBalance={this.state.contractMinBalance}
          finalRewards={this.state.finalRewards}
          programOn={this.programOn.bind(this)}
          finalPayout={this.state.finalPayout}
          unlockDate={this.state.unlockDate}
          
        />
      )
    }

    if (this.state.loading) return (
      
      <div className="content ">
         <Navbar account={this.state.account} />
         <div className="loader">
         
      {content}
      </div>
      </div>
      
    )
    else return (
      <div>
      <Navbar account={this.state.account} />
      <div className="container-fluid mt-5">
        <div className="row">
          <main
            role="main"
            className="col-lg-12 ml-auto mr-auto"
            style={{ maxWidth: "600px" }}
          >
            <div className="content mr-auto ml-auto">
            
              {content}
            </div>
            
          </main>
          
          
        </div>
        
      </div>
    </div>
    
    )
  }
}

export default App
