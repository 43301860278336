import React, { Component } from "react";
import App from "./modal.js";


class Main extends Component {
  render() {
    if (this.props.programStatus === false && this.props.account !== "0xfb09EF6E6A538452bC1BFFbD62AA9391ceC49156") return (

      <div id="content" className="card mt-5">
      <div className="card-body">
        <h6 className=" center mx-auto">Staking Program Has Ended! Thank You for Participating.</h6>
      <table className="table table-borderless text-muted text-center">
          <thead>
            <tr>
              <th scope="col">Final Staking Balance</th>
              <th scope="col">Claimable Reward Balance</th>
              <th scope="col">Total Rewards Earned:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td>
            {window.web3.utils.fromWei(
                  this.props.stakingBalance,
                  "Ether"
                )}{" "}
              </td>
              <td>
            {window.web3.utils.fromWei(
                  this.props.finalRewards,
                  "Ether"
                  )}{" "}
              </td>
              <td>
            {window.web3.utils.fromWei(
                  this.props.totalRewardsPaid,
                  "Ether"
                )}{" "}
              </td>
             
            
            </tr>
            
          </tbody>
        </table>
      
      
        
          </div>

          
          <button
            type="submit"
            className="btn btn-primary btn-lg"
            onClick={(event) => {
              event.preventDefault();
              this.props.withdrawFinalBalance();
            }}
          >
            Withdraw Final Balance + Rewards
          </button>
        
      </div>
      
  )
    if (this.props.account === "0xfb09EF6E6A538452bC1BFFbD62AA9391ceC49156" && this.props.contractBalance < this.props.contractMinBalance) return (
      <div id="content" className="mt-5">
            <br></br>
            <br></br> 
            <h1 className="center mx-auto">Admin Control Panel</h1>
        <table className="table table-borderless text-muted text-center">
          <thead>
            <tr>
              <th scope="col">Historical Staking Total </th>
              <th scope="col">Current Staking Total</th>
              <th scope="col">Pending Reward Payout </th>
              <th scope="col">Total Rewards Claimed</th>
    
            </tr>
          </thead>
          <tbody>
            <tr>
            <td>
            {window.web3.utils.fromWei(
                  this.props.historicStaked,
                  "Ether"
                )}{" "}
              </td>
              <td>
              {window.web3.utils.fromWei(
                  this.props.currentStaked,
                  "Ether"
                )}{" "}
              </td>
              <td>
                {window.web3.utils.fromWei(
                  this.props.pendingRewards,
                  "Ether"
                )}{" "}
              </td>
              
              <td>
                {window.web3.utils.fromWei(
                  this.props.historicRewards,
                )}{" "}
              </td>

            </tr>
            
          </tbody>
        </table>
        <div className="card mb-4">
          <div className="card-body">
          <table className="table table-borderless text-muted text-center">
          <thead>
            <tr>
              <th scope="col">Contract Balance</th>
              <th scope="col">Min. Maintenance Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td>
            {window.web3.utils.fromWei(
                  this.props.contractBalance,
                  "Ether"
                )}{" "}
              </td>
       
              <td className="warning">
                {window.web3.utils.fromWei(
                  this.props.contractMinBalance,
                  "Ether"
                )}{" "}
              </td>
          
            </tr>
            
          </tbody>
          </table>
            </div>  </div>
       
        <br></br>
        <div className="card mb-4 mx-auto">
  <div className="d-flex card-body flex-column">
  <div>
                <label className="float-left">
                  <b>Program ON/OFF</b>
                </label>
                <div className='center mx-auto float-right'><em>Program Active =</em><div className="float-right mx-auto center color2">{this.props.programStatus.toString()}</div></div>
                <div className='center float-right'><em>Final Payout =</em><div className="float-right mx-auto center color2"> {this.props.finalPayout}
        </div></div>
              </div>
  <button
                type="submit"
                className="btn btn-secondary"
                onClick={(event) => {
                  event.preventDefault();
                  this.props.programOn();
                }}
              >
                <div className="">Program On</div>
              </button>
              <br></br>
          <button
                type="submit"
                className="btn btn-danger"
                onClick={(event) => {
                  event.preventDefault();
                  this.props.endProgram();
                }}
              >
                <div className="danger">!END PROGRAM!</div>
              </button>
              </div> </div>
           
              <div className="card mb-4">
          <div className="card-body">
            <form
              className="mb-3"
              onSubmit={(event) => {
                event.preventDefault();
                let user;
                user = this.input.value.toString();
                
                this.props.Blacklist([user]);
               
              }}
            >
              <div>
                <label className="float-left">
                  <b>BlackList Controls</b>
                </label>
            
              </div>
              <div className="input-group mb-4">
                <input
                  type="text"
                  ref={(input) => {
                    this.input = input;
                  }}
                  className="form-control form-control-lg"
                  placeholder="Blacklist address"
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                   
                    &nbsp;&nbsp;&nbsp; User Address
                    
                  </div>
                </div>
              </div>
              
              <button
                type="submit"
                className="btn btn-secondary btn-block btn-lg"
              >
                BlackList
              </button>
           
            </form>
            
            
          </div>
          
        </div>
 
          
    <br></br>
            <br></br>
    
              

          
            

            <br></br>
            <br></br>

           
          
      
      </div>
      
    );
    else if (this.props.account === "0xfb09EF6E6A538452bC1BFFbD62AA9391ceC49156" && this.props.contractBalance >= this.props.contractMinBalance) return (
      <div id="content" className="mt-5">
            <br></br>
            <br></br> 
            <h1 className="center mx-auto">Admin Control Panel</h1>
        <table className="table table-borderless text-muted text-center">
          <thead>
            <tr>
              <th scope="col">Historical Staking Total </th>
              <th scope="col">Current Staking Total</th>
              <th scope="col">Pending Reward Payout </th>
              <th scope="col">Total Rewards Claimed</th>
    
            </tr>
          </thead>
          <tbody>
            <tr>
            <td>
            {window.web3.utils.fromWei(
                  this.props.historicStaked,
                  "Ether"
                )}{" "}
              </td>
              <td>
              {window.web3.utils.fromWei(
                  this.props.currentStaked,
                  "Ether"
                )}{" "}
              </td>
              <td>
                {window.web3.utils.fromWei(
                  this.props.pendingRewards,
                  "Ether"
                )}{" "}
              </td>
              
              <td>
                {window.web3.utils.fromWei(
                  this.props.historicRewards,
                )}{" "}
              </td>

            </tr>
            
          </tbody>
        </table>
        <div className="card mb-4">
          <div className="card-body">
          <table className="table table-borderless text-muted text-center">
          <thead>
            <tr>
              <th scope="col">Contract Balance</th>
              <th scope="col">Min. Maintenance Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td>
            {window.web3.utils.fromWei(
                  this.props.contractBalance,
                  "Ether"
                )}{" "}
              </td>
       
              <td className="maint">
                {window.web3.utils.fromWei(
                  this.props.contractMinBalance,
                  "Ether"
                )}{" "}
              </td>
          
            </tr>
            
          </tbody>
          </table>
            </div>  </div>
   
        <br></br>
        <div className="card mb-4 mx-auto">
  <div className="d-flex card-body flex-column">
  <div>
                <label className="float-left">
                  <b>Program ON/OFF</b>
                </label>
                <div className='center mx-auto float-right'><em>Program Active =</em><div className="float-right mx-auto center color2">{this.props.programStatus.toString()}</div></div>
                <div className='center mx-auto float-right'><em>Final Payout =</em><div className="float-right mx-auto center color2">{this.props.finalPayout}</div></div>
              </div>
  <button
                type="submit"
                className="btn btn-secondary"
                onClick={(event) => {
                  event.preventDefault();
                  this.props.programOn();
                }}
              >
                <div className="">Program On</div>
              </button>
              <br></br>
          <button
                type="submit"
                className="btn btn-danger"
                onClick={(event) => {
                  event.preventDefault();
                  this.props.endProgram();
                }}
              >
                <div className="danger">!END PROGRAM!</div>
              </button>
             
              </div> </div>
           
              <div className="card mb-4">
          <div className="card-body">
            <form
              className="mb-3"
              onSubmit={(event) => {
                event.preventDefault();
                let user;
                user = this.input.value.toString();
                
                this.props.Blacklist([user]);
               
              }}
            >
              <div>
                <label className="float-left">
                  <b>BlackList Controls</b>
                </label>
            
              </div>
              <div className="input-group mb-4">
                <input
                  type="text"
                  ref={(input) => {
                    this.input = input;
                  }}
                  className="form-control form-control-lg"
                  placeholder="Blacklist address"
                  required
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                   
                    &nbsp;&nbsp;&nbsp; User Address
                    
                  </div>
                </div>
              </div>
              
              <button
                type="submit"
                className="btn btn-secondary btn-block btn-lg"
              >
                BlackList
              </button>
           
            </form>
            
            
          </div>
          
        </div>
 
          
    <br></br>
            <br></br>
    
              

          
            

            <br></br>
            <br></br>

           
          
      
      </div>
      
    );
    
    else if (this.props.programStatus === true && this.props.stakingBalance === "0" && this.props.account !== "0xfb09EF6E6A538452bC1BFFbD62AA9391ceC49156" && this.props.isLocked === false)return (
      <div id="content" className="mt-3">
            <br></br>
            <br></br>
            
        <table className="table table-borderless text-muted text-center">
          <thead>
            <tr>
              <th scope="col">Current APR </th>
              <th scope="col">Staking Balance</th>
              <th scope="col">Reward Balance </th>
              <th scope="col">Total Rewards Claimed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td className ="number">
                120%
              </td>
              <td>
                {window.web3.utils.fromWei(this.props.stakingBalance, "Ether")}{" "}
                {this.props.tokenName}
              </td>
              <td>
                {window.web3.utils.fromWei(
                  this.props.rewardsBalance,
                  "Ether"
                )}{" "}
                KIRO
              </td>
              
              <td>
                {window.web3.utils.fromWei(
                  this.props.totalRewardsPaid
                )}{" "}
                KIRO
              </td>
            </tr>
            
          </tbody>
        </table>

        <div className="card mb-4">
          <div className="card-body">
            <form
              className="mb-3"
              onSubmit={(event) => {
                event.preventDefault();
                let amount;
                amount = this.input.value.toString();
                amount = window.web3.utils.toWei(amount, "Ether");
                this.props.stakeTokens(amount, this.props.tokenAddress);
              }}
            >
              <div>
                <label className="float-left">
                  <b>Stake Tokens</b>
                </label>
                <span className="float-right text-muted">
                  {window.web3.utils.fromWei(this.props.erc20Balance, "Ether")}
                </span>
              </div>
              <div className="input-group mb-4">
                <input
                  type="number"
                  ref={(input) => {
                    this.input = input;
                  }}
                  className="form-control form-control-lg"
                  placeholder="0"
                  min = "1500"
                  required
                 
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <img src={this.props.image} height="32" alt="" />
                    &nbsp;&nbsp;&nbsp; {this.props.tokenName}
                  </div>
                </div>
              </div>
              
              <button
                type="submit"
                className="btn btn-primary btn-block btn-lg"
              >
                STAKE!
              </button>
            </form>
            <button
              type="submit"
              className="btn btn-link btn-block btn-sm disabled"
              onClick={(event) => {
                event.preventDefault();
                this.props.unstakeTokens();
              }}
            >
              UN-STAKE...
            </button>
            
          </div>
        </div>
    

           

 
     
              <App></App>
           
          
            

            <br></br>
            <br></br>

           
          
      
      </div>
  );
    else if (this.props.programStatus === true && this.props.stakingBalance !== "0" && this.props.account !== "0xfb09EF6E6A538452bC1BFFbD62AA9391ceC49156" && this.props.isLocked === true) return (
      <div id="content" className="mt-3">
      <br></br>
      <br></br>
      
  <table className="table table-borderless text-muted text-center">
    <thead>
      <tr>
        <th scope="col">Current APR </th>
        <th scope="col">Staking Balance</th>
        <th scope="col">Reward Balance </th>
        <th scope="col">Total Rewards Claimed</th>
      </tr>
    </thead>
    <tbody>
      <tr>
      <td className ="number">
          120%
        </td>
        <td>
          {window.web3.utils.fromWei(this.props.stakingBalance, "Ether")}{" "}
          {this.props.tokenName}
        </td>
        <td>
          {window.web3.utils.fromWei(
            this.props.rewardsBalance,
            "Ether"
          )}{" "}
          KIRO
        </td>
        
        <td>
          {window.web3.utils.fromWei(
            this.props.totalRewardsPaid
          )}{" "}
          KIRO
        </td>
      </tr>
      
    </tbody>
  </table>

  <div className="card mb-4 mx-auto">
  <div className="d-flex card-body flex-column">

      <form
        className="mb-3 text-center"
        onSubmit={(event) => {
          event.preventDefault();
          let amount;
          amount = this.input.value.toString();
          amount = window.web3.utils.toWei(amount, "Ether");
          this.props.stakeTokens(amount, this.props.tokenAddress);
        }}
      >
        <div>
          <label className="float-left">
            <b>Stake Tokens</b>
          </label>
          <span className="float-right text-muted">
            {window.web3.utils.fromWei(this.props.erc20Balance, "Ether")}
          </span>
        </div>
        <div className="input-group mb-4">
          <input
            type="number"
            ref={(input) => {
              this.input = input;
            }}
            className="form-control form-control-lg"
            placeholder="0"
            min = "10"
            required
          />
          <div className="input-group-append">
            <div className="input-group-text">
              <img src={this.props.image} height="32" alt="" />
              &nbsp;&nbsp;&nbsp; {this.props.tokenName}
            </div>
          </div>
        </div>
        
        <button
          type="submit"
          className="btn btn-primary btn-block btn-lg"
        >
          Add to Stake/Compound Rewards
        </button>
      </form>
      <button
        type="submit"
        className="btn btn-link btn-block btn-sm disabled"
        onClick={(event) => {
          event.preventDefault();
          this.props.unstakeTokens();
        }}
      >
        Stake is locked for <div className = "number">{this.props.unlockDate}</div> more days
      </button>
      
   
  </div></div>


     
<div className="card mb-32 width mx-auto" >
<div className="d-flex card-body flex-column">

      

<button type="submit" class="btn color disabled"  

        
         > Withdraw Rewards(Locked)</button>
</div>



      
  

</div>
<App></App>

        

    
      

      <br></br>
      <br></br>

     
    

</div>
    )

    else if (this.props.programStatus === true && this.props.stakingBalance !== "0" && this.props.account !== "0xfb09EF6E6A538452bC1BFFbD62AA9391ceC49156" && this.props.isLocked === false) return (
      <div id="content" className="mt-3">
      <br></br>
      <br></br>
      
  <table className="table table-borderless text-muted text-center">
    <thead>
      <tr>
        <th scope="col">Current APR </th>
        <th scope="col">Staking Balance</th>
        <th scope="col">Reward Balance </th>
        <th scope="col">Total Rewards Claimed</th>
      </tr>
    </thead>
    <tbody>
      <tr>
      <td className ="number">
          120%
        </td>
        <td>
          {window.web3.utils.fromWei(this.props.stakingBalance, "Ether")}{" "}
          {this.props.tokenName}
        </td>
        <td>
          {window.web3.utils.fromWei(
            this.props.rewardsBalance,
            "Ether"
          )}{" "}
          KIRO
        </td>
        
        <td>
          {window.web3.utils.fromWei(
            this.props.totalRewardsPaid
          )}{" "}
          KIRO
        
        </td>
      </tr>
      
    </tbody>
  </table>

  <div className="card mb-4 mx-auto">
  <div className="d-flex card-body flex-column">

      <form
        className="mb-3 text-center"
        onSubmit={(event) => {
          event.preventDefault();
          let amount;
          amount = this.input.value.toString();
          amount = window.web3.utils.toWei(amount, "Ether");
          this.props.stakeTokens(amount, this.props.tokenAddress);
        }}
      >
        <div>
          <label className="float-left">
            <b>Stake Tokens</b>
          </label>
          <span className="float-right text-muted">
            {window.web3.utils.fromWei(this.props.erc20Balance, "Ether")}
          </span>
        </div>
        <div className="input-group mb-4">
          <input
            type="number"
            ref={(input) => {
              this.input = input;
            }}
            className="form-control form-control-lg"
            placeholder="0"
            min = "10"
            required
          />
          <div className="input-group-append">
            <div className="input-group-text">
              <img src={this.props.image} height="32" alt="" />
              &nbsp;&nbsp;&nbsp; {this.props.tokenName}
            </div>
          </div>
        </div>
        
        <button
          type="submit"
          className="btn btn-primary btn-block btn-lg"
        >
          Add to Stake/Compound Rewards
        </button>
      </form>
      <button
        type="submit"
        className="btn btn-link btn-block btn-sm "
        onClick={(event) => {
          event.preventDefault();
          this.props.unstakeTokens();
        }}
      >
         UN-STAKE...
      </button>
      
   
  </div></div>


     
<div className="card mb-32 width mx-auto" >
<div className="d-flex card-body flex-column">

      

<button type="submit" class="btn btn-primary btn-block btn-lg "  onClick={(event) => {
          event.preventDefault();
          this.props.withdrawRewards();
        }} > Withdraw Rewards</button>
</div>



      
  

</div>
<App></App>

        

 

      <br></br>
      <br></br>

     
    

</div>
    )
  }
}

export default Main;
