import React, { Component } from "react";
import symbol from "../logo white.png";

class Navbar extends Component {
  render() {
    return (
      <nav className="navbar dropdown-menu navbar-dark fixed-top bg-primary flex-md-nowrap p-0 shadow">
      
        <a
          className="navbar-brand col-sm-3 col-md-2 mr-0"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.kirobo.io"
        >
          <img
            src={symbol}
            width="70%"
            height="80"
            className="d-inline-block align-top logo"
            alt=""
          />
          &nbsp; 
        </a>

        <ul className="navbar-nav px-3">
          <li className="nav-item text-nowrap d-none d-sm-none d-sm-block">
            <large className="text-white">
              <large id="account" className ="connect">Connected  To   Account:</large>     <large className=""> {this.props.account}</large>
            </large>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Navbar;
