import React from "react";

import Modal from "react-bootstrap/Modal";


const App = () => {
    const [isOpen, setIsOpen] = React.useState(false);
  
    const showModal = () => {
      setIsOpen(true);
    };
  
    const hideModal = () => {
      setIsOpen(false);
    };
  
    return (
      <>
      <div className='container'>
        <button type="btn"  className ="center1 btn btn-link"onClick={showModal}>Click here for Program Info</button></div>
        <Modal className ="shadow "show={isOpen} onHide={hideModal} centered> 
          <Modal.Header>
            <Modal.Title className=" title2">Program Info</Modal.Title>
          </Modal.Header>
          <Modal.Body><ul>
          <li>You must stake a minimum of 1500 Kiro to earn rewards.</li> 
          <li> APR is 120%, or 10% monthly. Reward balance is calculated in real time and will update automatically.</li> 
          <li>Stake will be locked for 30 days before balance or rewards can be withdrawn.</li> 
          <li> You can add to your stake or compound rewards you have already earned (10 Kiro minimum).</li>
          <li> Adding to Stake or Compounding Rewards will reset the lock for another 30 days.</li> 
          <li>Thank you for your participation!</li> 
              </ul></Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
      </>
    
  );
};
export default App;